import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Grid, Box } from "@mui/material";
import Text from "../components/data-display/text";
import { useTheme } from "@mui/system";
import InlineLink from "../components/data-display/inline-link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FiChevronRight } from 'react-icons/fi';
import { WebsiteLink, logoJPG } from '../utils/constant.urls';
import SEO from '../components/seo/seo';

const TaskPage = ({ pageContext: { title, description, category, createAt, id, slug_title } }) => {
    const theme = useTheme();
    return (
        <div style={{ background: "white", minHeight: "100vh", borderBottom: "1px solid rgb(220,220,220)" }}>
            <SEO description={description} title={title} image={logoJPG}
                pathname={`${WebsiteLink}/tasks${slug_title}`} />

            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={`${WebsiteLink}/tasks${slug_title}`} />
                {/*<meta property="og:image" content={`${siteUrl}/static/QuickTaskicon-9923c1ab262bd9d96ebc3b9385c8f9e2.png`} />*/}
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                {/*<meta name="twitter:image" content={`${siteUrl}/static/QuickTaskicon-9923c1ab262bd9d96ebc3b9385c8f9e2.png`} />*/}
            </Helmet>
            <Box style={{ height: "100px" }} />
            <Container maxWidth={'lg'}>
                <Breadcrumbs aria-label="breadcrumb" separator={<FiChevronRight size={12} color={"grey"} />}>
                    <Text variant={'body2'}>
                        <InlineLink to="/tasks" style={{ color: "black" }}>
                            Tasks
                        </InlineLink>
                    </Text>
                    <Text variant={'body2'} style={{ opacity: "0.5" }}>{category}</Text>
                </Breadcrumbs>
                <Box style={{ height: "64px" }} />
                <Text variant={'h4'} medium style={{ color: "black" }}>{title}</Text>
                <Box style={{ height: "64px" }} />
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Text variant={'body1'} regular style={{ color: "black" }}>
                            {description}
                        </Text>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box>
                            <Text variant={'body2'} color="textSecondary">Category</Text>
                            <Text variant={'body1'}>{category}</Text>
                        </Box>
                        <Box mt={2}>
                            <Text variant={'body2'} color="textSecondary">Created At</Text>
                            <Text variant={'body1'}>{new Date(createAt).toLocaleDateString()}</Text>
                        </Box>
                        <Box mt={2}>
                            <Text variant={'body2'} color="textSecondary">Task ID</Text>
                            <Text variant={'body1'}>{id}</Text>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default TaskPage;
